 import MecLabCoverPage from "./Container/MecLabCoverPage/MecLabCoverPage";
import FormOfCoverPage from "./Components/FormOfCoverPage/FormOfCoverPage";
function App() {
  return (
    <div >
      {/* <MecLabCoverPage/>  */}
   <FormOfCoverPage/>
    </div>
  );
}

export default App;
